import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule, DecimalPipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { BnNgIdleService } from "bn-ng-idle";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { AuthInterceptor } from "./auth.interceptor";
import { TokenStorageService } from "./service/TokenStorageService/token-storage.service";
import { CommonEffects } from "./store/common/common.effects";
import { commonReducer } from "./store/common/common.reducers";
import { IncomeCalculationEffects } from "./store/incomeCalculation/incomeCalculation.effects";
import { IncomeCalculationReducer } from "./store/incomeCalculation/incomeCalculation.reducer";
import { LoanEffects } from "./store/loan/loan.effects";
import { loanReducer } from "./store/loan/loan.reducer";
import { RuleEngineEffects } from "./store/rule-engine/rule-engine.effects";
import { ruleEngineReducer } from "./store/rule-engine/rule-engine.reducer";
import { SummaryEffects } from "./store/summary/summary.effects";
import { summaryReducer } from "./store/summary/summary.reducers";
import { ExtractionEffects } from "./store/transaction/transaction.effects";
import { transactionReducer } from "./store/transaction/transaction.reducer";

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.warn(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
      knownAuthorities: [environment.b2cPolicies.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.defaultScope,
    },
    loginFailedRoute: "/app-login-failed",
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MatSnackBarModule,
    PdfViewerModule,
    ComponentsModule,
    CommonModule,
    MultiSelectModule,
    DropdownModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature("feature_common", commonReducer),
    EffectsModule.forFeature([CommonEffects]),
    StoreModule.forFeature("transaction_feature", transactionReducer),
    EffectsModule.forFeature([ExtractionEffects]),
    StoreModule.forFeature("summary_feature", summaryReducer),
    EffectsModule.forFeature([SummaryEffects]),
    StoreModule.forFeature("loan_feature", loanReducer),
    EffectsModule.forFeature([LoanEffects]),
    StoreModule.forFeature("incomeCalculation_feature", IncomeCalculationReducer),
    EffectsModule.forFeature([IncomeCalculationEffects]),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature("ruleEngine", ruleEngineReducer),
    EffectsModule.forFeature([RuleEngineEffects]),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
  ],
  providers: [
    { provide: "BASE_URL", useValue: environment.base_url },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DecimalPipe,
    TokenStorageService,
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
