import {
  ASSIGN_NEW_LOAN,
  ASSIGN_NEW_LOAN_FAILURE,
  ASSIGN_NEW_LOAN_SUCCESS,
  CLEAR_INITIATE_LOAN_FAILURE,
  DELETE_BANK_ACCOUNT_BY_ID,
  DELETE_BANK_ACCOUNT_BY_ID_FAILURE,
  DELETE_BANK_ACCOUNT_BY_ID_SUCCESS,
  GET_LOAN_ACCOUNT_AND_ASSIGNED_TO,
  GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_FAILURE,
  GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_SUCCESS,
  GET_LOAN_BY_ID,
  GET_LOAN_BY_ID_FAILURE,
  GET_LOAN_BY_ID_SUCCESS,
  GET_LOAN_FILTERS,
  GET_LOAN_FILTERS_FAILURE,
  GET_LOAN_FILTERS_SUCCESS,
  GET_LOAN_UPDATE_STATUS,
  GET_LOAN_UPDATE_STATUS_FAILURE,
  GET_LOAN_UPDATE_STATUS_SUCCESS,
  GET_LOANS,
  GET_LOANS_FAILURE,
  GET_LOANS_SUCCESS,
  INITIATE_LOAN,
  INITIATE_LOAN_FAILURE,
  INITIATE_LOAN_SUCCESS,
  LoanActions,
  RESET_LOANS,
  RESET_LOANS_INFO,
} from "./loan.actions";

import { LoanDetailsState } from "./loan.types";

const initialState: LoanDetailsState = {
  loanDetails: { data: [], errorMessage: undefined },
  loanFilterData: { data: [], errorMessage: undefined },
  getLoanCountAndAssignedToResult: { data: undefined, errorMessage: undefined, loading: true },
  updateLoanStatus: { data: undefined, errorMessage: undefined },
  assignNewLoanstring: { data: undefined, errorMessage: undefined, loading: true },
  initiatedLoanId: { data: undefined, errorMessage: undefined, loading: true },
  deleteBankAccountById: { data: undefined, errorMessage: undefined, loading: true },
  loanDetailsById: { data: undefined, errorMessage: undefined, loading: true },
};

export function loanReducer(state = initialState, action: LoanActions): LoanDetailsState {
  switch (action.type) {
    case GET_LOANS:
    case GET_LOAN_FILTERS:
    case GET_LOAN_ACCOUNT_AND_ASSIGNED_TO:
    case GET_LOAN_UPDATE_STATUS:
    case ASSIGN_NEW_LOAN:
      return state;

    case GET_LOANS_SUCCESS: {
      return {
        ...state,
        loanDetails: {
          errorMessage: undefined,
          data: state.loanDetails.data.concat(action.data),
        },
      };
    }
    case GET_LOANS_FAILURE: {
      return {
        ...state,
        loanDetails: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case RESET_LOANS: {
      return {
        ...state,
        loanDetails: {
          errorMessage: undefined,
          data: [],
        },
      };
    }

    case RESET_LOANS_INFO: {
      return {
        ...state,
        assignNewLoanstring: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }

    case GET_LOAN_FILTERS_SUCCESS: {
      return {
        ...state,
        loanFilterData: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_LOAN_FILTERS_FAILURE: {
      return {
        ...state,
        loanFilterData: {
          errorMessage: action.message,
          data: [],
        },
      };
    }

    case ASSIGN_NEW_LOAN_SUCCESS: {
      return {
        ...state,
        assignNewLoanstring: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case ASSIGN_NEW_LOAN_FAILURE: {
      return {
        ...state,
        assignNewLoanstring: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    case INITIATE_LOAN: {
      return {
        ...state,
        initiatedLoanId: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }
    case INITIATE_LOAN_SUCCESS: {
      return {
        ...state,
        initiatedLoanId: {
          errorMessage: undefined,
          data: action.loanData,
        },
      };
    }
    case INITIATE_LOAN_FAILURE: {
      return {
        ...state,
        initiatedLoanId: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    case CLEAR_INITIATE_LOAN_FAILURE: {
      return {
        ...state,
        initiatedLoanId: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }

    case DELETE_BANK_ACCOUNT_BY_ID: {
      return {
        ...state,
        deleteBankAccountById: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }

    case DELETE_BANK_ACCOUNT_BY_ID_SUCCESS: {
      return {
        ...state,
        deleteBankAccountById: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }

    case DELETE_BANK_ACCOUNT_BY_ID_FAILURE: {
      return {
        ...state,
        deleteBankAccountById: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    case GET_LOAN_BY_ID: {
      return {
        ...state,
        loanDetailsById: {
          errorMessage: undefined,
          data: undefined,
        },
      };
    }
    case GET_LOAN_BY_ID_SUCCESS: {
      return {
        ...state,
        loanDetailsById: {
          data: action.loanData,
          errorMessage: undefined,
        },
      };
    }
    case GET_LOAN_BY_ID_FAILURE: {
      return {
        ...state,
        loanDetailsById: {
          data: undefined,
          errorMessage: action.message,
        },
      };
    }
    case GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_SUCCESS:
      return {
        ...state,
        getLoanCountAndAssignedToResult: {
          data: action.data, // Ensure data is assigned correctly
          errorMessage: undefined,
          loading: false,
        },
      };

    case GET_LOAN_ACCOUNT_AND_ASSIGNED_TO_FAILURE:
      return {
        ...state,
        getLoanCountAndAssignedToResult: {
          data: undefined, // Clear data on failure
          errorMessage: action.message,
          loading: false,
        },
      };

    case GET_LOAN_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        updateLoanStatus: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_LOAN_UPDATE_STATUS_FAILURE: {
      return {
        ...state,
        updateLoanStatus: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    default:
      return state;
  }
}
