import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { GlobalWorkerOptions } from "pdfjs-dist";
import { AppModule } from "./app/app.module";

GlobalWorkerOptions.workerSrc = "assets/pdf.worker.min.mjs";

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
