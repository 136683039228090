import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import {
  AssignNewLoan,
  AssignNewLoanFailure,
  AssignNewLoanSuccess,
  DeleteBankAccountById,
  DeleteBankAccountByIdFailure,
  DeleteBankAccountByIdSuccess,
  GetLoanAccountAndAssignedTo,
  GetLoanAccountAndAssignedToFailure,
  GetLoanAccountAndAssignedToSuccess,
  GetLoanById,
  GetLoanByIdFailure,
  GetLoanByIdSuccess,
  GetLoanFilters,
  GetLoanFiltersFailure,
  GetLoanFiltersSuccess,
  GetLoans,
  GetLoansFailure,
  GetLoansSuccess,
  GetLoansUpdateStatus,
  GetLoansUpdateStatusFailure,
  GetLoansUpdateStatusSuccess,
  InitiateLoan,
  InitiateLoanFailure,
  InitiateLoanSuccess,
} from "./loan.actions";
import { GetLoanCountAndAssignedToResult } from "./loan.types";

import { LoanService } from "src/app/service/LoanService/loan.service";

@Injectable()
export class LoanEffects {
  constructor(
    private actions$: Actions,
    private loanService: LoanService
  ) {}

  CreateLoan = createEffect(() =>
    this.actions$.pipe(
      ofType(InitiateLoan),
      mergeMap((action) =>
        this.loanService.createLoan(action.loanData).pipe(
          map((response) => {
            if (response.success) {
              return InitiateLoanSuccess({ loanData: response.data });
            } else {
              return InitiateLoanFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(InitiateLoanFailure({ message: error.error.message })))
        )
      )
    )
  );

  GetLoanByID = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoanById),
      mergeMap((action) =>
        this.loanService.getLoadById(action.loanId).pipe(
          map((response) => {
            if (response.success) {
              return GetLoanByIdSuccess({ loanData: response.data });
            } else {
              return GetLoanByIdFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(GetLoanByIdFailure({ message: error.error.message })))
        )
      )
    )
  );

  GetLoans = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoans),
      mergeMap((action) =>
        this.loanService.getLoans(action.data).pipe(
          map((response) => {
            if (response.success) {
              return GetLoansSuccess({ data: response.data });
            } else {
              return GetLoansFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(GetLoansFailure({ message: error })))
        )
      )
    )
  );

  DeleteBankAccountById = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteBankAccountById),
      mergeMap((action) =>
        this.loanService.deleteBankAccountById(action.bankAccountId).pipe(
          map((response) => {
            if (response.success) {
              return DeleteBankAccountByIdSuccess({ data: response.data });
            } else {
              return DeleteBankAccountByIdFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(DeleteBankAccountByIdFailure({ message: error })))
        )
      )
    )
  );

  GetLoanFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoanFilters),
      mergeMap((action) =>
        this.loanService.getLoanFiltersData(action.data).pipe(
          map((response) => {
            if (response.success) {
              return GetLoanFiltersSuccess({ data: response.data });
            } else {
              return GetLoanFiltersFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(GetLoanFiltersFailure({ message: error })))
        )
      )
    )
  );

  //pavan changes
  GetLoanAccountAndAssignedTo = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoanAccountAndAssignedTo),
      mergeMap((action) =>
        this.loanService.getLoanCountAndAssignedTo(action.userId).pipe(
          map((response: { success: boolean; data: GetLoanCountAndAssignedToResult }) => {
            if (response.success) {
              return GetLoanAccountAndAssignedToSuccess({ data: response.data });
            } else {
              return GetLoanAccountAndAssignedToFailure({
                errors: [],
                message: "Failed to fetch loan data",
                userId: action.userId,
              });
            }
          }),
          catchError((error) =>
            of(GetLoanAccountAndAssignedToFailure({ message: error.message || "Unknown error", userId: action.userId }))
          )
        )
      )
    )
  );

  GetLoansUpdateStatusDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLoansUpdateStatus),
      mergeMap((action) =>
        this.loanService.updateLoanStatus(action.data).pipe(
          map((response) => {
            if (response.success) {
              return GetLoansUpdateStatusSuccess({
                data: response.data,
              });
            } else {
              return GetLoansUpdateStatusFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => {
            console.error("Update loan status failed", error);
            return of(GetLoansUpdateStatusFailure({ message: error.message || "Unknown error" }));
          })
        )
      )
    )
  );

  //Pavan Chnages

  AssignNewLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignNewLoan),
      mergeMap((action) =>
        this.loanService.assignNewLoan(action.userId).pipe(
          map((response) => {
            if (response.data) {
              return AssignNewLoanSuccess({ data: response.data });
            } else {
              return AssignNewLoanFailure({ errors: response.errors, message: response.message });
            }
          }),
          catchError((error) => of(AssignNewLoanFailure({ message: error })))
        )
      )
    )
  );
}
