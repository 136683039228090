import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { IncomeCalculationServiceService } from "src/app/service/IncomeCalculation/income-calculation.service";
import { GetExportLoan, GetExportLoanFailure, GetExportLoanSuccess } from "./incomeCalculation.actions";

@Injectable()
export class IncomeCalculationEffects {
  constructor(
    private actions$: Actions,
    private incomeCalculationService: IncomeCalculationServiceService
  ) {}

  GetExportLoan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetExportLoan),
      mergeMap((action) =>
        this.incomeCalculationService.exportToExcel(action.LoanId, action.loanNumber).pipe(
          map(() => GetExportLoanSuccess({ data: "success" })),
          catchError((error) => of(GetExportLoanFailure({ message: error })))
        )
      )
    )
  );
}
