import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiResponseModel, IUser } from "src/app/models/common.model";
import { AccountType, BuisnessType, EmployeeCount, TypeOfBusiness } from "src/app/models/loans";
import { AppConfigState } from "src/app/store/app.states";
import { TokenStorageService } from "../TokenStorageService/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  idToken: string;
  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    @Inject("BASE_URL") private baseUrl: string
  ) {
    this.idToken = tokenStorageService.getAccessToken() || "";
  }

  getBuisnessTypes() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<BuisnessType[]>>(this.baseUrl + `api/Common/BusinessType`, { headers });
  }

  GetAppConfig() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<AppConfigState>("assets/app.config.json", { headers });
  }

  getUserById(userId: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<IUser>>(
      this.baseUrl + `api/User/${userId}?projectId=${this.tokenStorageService.getCurrentProjectIdForBSIC()}`,
      { headers }
    );
  }

  getAccountTypes() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<AccountType[]>>(this.baseUrl + `api/Common/AccountType`, { headers });
  }
  getTypeOfBusiness() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<TypeOfBusiness[]>>(this.baseUrl + `api/Common/TypeOfBusiness`, { headers });
  }
  getEmployeeCount() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.idToken}`,
    });
    return this.http.get<ApiResponseModel<EmployeeCount[]>>(this.baseUrl + `api/Common/EmployeeCount`, { headers });
  }
}
