import {
  CLEAR_RULE_STATE,
  GET_ADDED_RULE_FAILURE,
  GET_ADDED_RULE_SUCCESS,
  GET_LOS_FIELD_LIST_SUCCESS,
  GET_RULE_BY_ID_SUCCESS,
  GET_UPDATED_RULE_SUCCESS,
  RuleEngineActions,
  UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP,
  UPDATE_DOCUMENT_TYPES,
  UPDATE_RULE_FILTERS,
} from "./rule-engine.actions";
import { RuleEngineState } from "./rule-engine.types";

const initialState: RuleEngineState = {
  rule: { data: undefined, errorMessage: undefined },
  documentTypes: [],
  documentFieldVersionListLookUp: {},
  LOSFieldLost: [],
  ruleFilters: undefined,
};

export function ruleEngineReducer(state = initialState, action: RuleEngineActions): RuleEngineState {
  switch (action.type) {
    case GET_RULE_BY_ID_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rules[0],
          errorMessage: undefined,
        },
      };

    case GET_ADDED_RULE_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: undefined,
        },
      };
    
    case GET_ADDED_RULE_FAILURE:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: action.errorMessage,
        }
      }

    case GET_UPDATED_RULE_SUCCESS:
      return {
        ...state,
        rule: {
          data: action.rule,
          errorMessage: undefined,
        },
      };

    case CLEAR_RULE_STATE:
      return {
        ...state,
        rule: {
          data: undefined,
          errorMessage: undefined,
        },
      };

    case UPDATE_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.documentTypes,
      };

    case UPDATE_DOCUMENT_FIELD_VERSION_LIST_LOOKUP:
      return {
        ...state,
        documentFieldVersionListLookUp: action.documentFieldVersionListLookUp,
      };

    case GET_LOS_FIELD_LIST_SUCCESS:
      return {
        ...state,
        LOSFieldLost: action.LOSFieldList,
      };

    case UPDATE_RULE_FILTERS:
      return {
        ...state,
        ruleFilters: action.ruleFilters,
      };

    default:
      return state;
  }
}
