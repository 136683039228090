import { Component, Inject, OnDestroy, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { BnNgIdleService } from "bn-ng-idle";
import { filter, Subject, takeUntil } from "rxjs";
import { TokenStorageService } from "./service/TokenStorageService/token-storage.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    title = "MSuit";
    isIframe = false;
    isSessionExpired = false;
    isLoggedIn = false;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private idleService: BnNgIdleService,
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private tokenStorageService: TokenStorageService
    ) {
        this.idleService.startWatching(1200).subscribe((isExpired) => {
            if (isExpired) {
                this.isSessionExpired = true;
                this.tokenStorageService.clearStorage();
            }
        });
    }

    ngOnInit(): void {
        localStorage.removeItem("isTransactionReviewScreenActive");
        this.isIframe = window !== window.parent && !window.opener;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status) => status === InteractionStatus.None),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.updateLoginStatus();
                this.ensureActiveAccount();
            });

         
    }

    updateLoginStatus(): void {
        this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
    }

    ensureActiveAccount(): void {
        const activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            const accounts = this.msalService.instance.getAllAccounts();
            const firstAccount: AccountInfo = accounts[0];
            this.msalService.instance.setActiveAccount(firstAccount);
            this.tokenStorageService.setAuthLoginData(firstAccount);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
