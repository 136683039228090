import {
  GET_EXPORT_LOANS,
  GET_EXPORT_LOANS_FAILURE,
  GET_EXPORT_LOANS_SUCCESS,
  IncomeCalculationActions,
} from "./incomeCalculation.actions";
import { IncomeCalculationState } from "./incomeCalculation.types";

const initialState: IncomeCalculationState = {
  exportExcel: { data: "", errorMessage: undefined },
};

export function IncomeCalculationReducer(
  state = initialState,
  action: IncomeCalculationActions
): IncomeCalculationState {
  switch (action.type) {
    case GET_EXPORT_LOANS:
      return state;

    case GET_EXPORT_LOANS_SUCCESS: {
      return {
        ...state,
        exportExcel: {
          errorMessage: undefined,
          data: action.data,
        },
      };
    }
    case GET_EXPORT_LOANS_FAILURE: {
      return {
        ...state,
        exportExcel: {
          errorMessage: action.message,
          data: undefined,
        },
      };
    }
    default:
      return state;
  }
}
